<template>
  <div class="col-auto border border-dark p-1 rounded bg-light">
    <b-row class="m-0 p-0">
      <b-col class="col-sm-auto m-0 p-0">
        <label for="input-documentHash"
          ><span id="dochashTooltip" :class="{ bold: validation }">{{ idKey }}*</span>:</label
        >
        <b-tooltip target="dochashTooltip" triggers="hover"> Overrides all search fields </b-tooltip>
      </b-col>
      <b-col class="col-sm-auto">
        <b-form-input
          id="input-documentHash"
          ref="documentHash"
          v-model="localIdValue"
          type="search"
          :placeholder="placeholderValue"
          trim
          debounce="500"
          tabindex="-1"
          size="sm"
          :maxlength="maxIdLength"
          :state="validation"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation">
          Must be {{ maxIdLength }} characters long.
        </b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="m-0 p-0">
      <b-col class="col-sm-auto m-0 p-0">
        <b v-show="validation">Overrides all other search fields</b>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import xlibs from '../../js/xlibs'
import xcomputed from '../../js/xcomputed'
import xconfig from '../../js/xconfig'

export default {
  name: 'ControlDocumentHashFilter',
  props: {
    resetDochash: {
      type: Boolean,
      default: false
    },
    spotlightMode: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    resetSearchIdField(newValue) {
      if (newValue) {
        this.clearField()
      }
    }
  },
  computed: {
    isAdmin: xcomputed.isAdmin,
    isLive: xcomputed.isLive,
    isStatic: xcomputed.isStatic,
    idKey() {
      return this.spotlightMode ? 'spotlightId' : 'documentHash'
    },
    ...mapGetters(['articles', 'articleFilters', 'curatingFrom', 'collectionType', 'collectionName']),
    ...mapState({
      documentHash: (state) => _.get(state, 'articleFilters.documentHash'),
      spotlightId: (state) => _.get(state, 'articleFilters.spotlightId')
    }),
    placeholderValue() {
      return `Enter ${this.idKey}`
    },
    localIdValue: {
      get: function () {
        return this.spotlightMode ? this.spotlightId : this.documentHash
      },
      set: function (newValue) {
        this.updateFormFilter(newValue)
      }
    },
    maxIdLength() {
      return this.spotlightMode ? xconfig.formsConfig.spotlightId.maxLength : xconfig.formsConfig.documentHash.length
    },
    validation() {
      const validCheck = _.size(this.localIdValue) == this.maxIdLength
      const valid = !_.size(this.localIdValue) ? null : validCheck
      return valid
    },
    isValid() {
      return this.validation === null || this.validation
    }
  },
  methods: {
    async updateFormFilter(formText) {
      const dispatchFnObj = this.spotlightMode ? { spotlightId: formText } : { documentHash: formText }
      await this.$store.dispatch('setArticleFilter', dispatchFnObj)
      if (this.isValid) {
        await xlibs.applyFilters.apply(this)
      }
      if (!_.isEmpty(formText)) this.$refs.documentHash.$el.focus()
    },
    async clearField() {
      const keyfield = this.spotlightMode ? this.spotlightId : this.documentHash
      if (!_.isEmpty(keyfield)) {
        return this.updateFormFilter('')
      }
    }
  }
}
</script>
