n
<template>
  <div class="ingested-page main-template" :class="{ polarity: isAdmin }">
    <div class="position-absolute statsBox">
      <curationStats ref="curationStats" />
      <subNav />
    </div>
    <div class="container-fluid form-controls">
      <b-form-checkbox
        v-if="isAdmin || isStatic"
        v-model="toggleIdSearchOn"
        name="mainSearch-button"
        tabindex="-1"
        switch
        >documentHash search
      </b-form-checkbox>
      <b-collapse id="idSearch-off" v-model="toggleIdSearchOff">
        <div v-if="isAdmin || isStatic">
          <controlUserFilter />
        </div>
        <div class="form-row">
          <controlCompanyFilter />
        </div>
        <div class="form-row">
          <controlHeadlineFilter />
        </div>
        <div v-if="isAdmin && !isStatic">
          <controlScoreModifiedFilter />
        </div>
        <controlDateFilter :subtract-days-from-start="isStatic ? 0 : 30" />
      </b-collapse>
      <div v-if="isAdmin || isStatic" class="form-row mt-1">
        <div class="col-auto">
          <b-collapse id="idSearch-on" v-model="toggleIdSearchOn">
            <div class="form-row documentHashControl">
              <controlDocumentHashFilter :reset-search-id-field="toggleIdSearchOn" />
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="container-fluid count">showing {{ count.currentCount }} of {{ count.totalCount }} articles</div>
    <div id="search-spinner" class="container-fluid">
      <div class="col screen-centered"><div class="spinner" /></div>
    </div>
    <div v-if="articles" class="container-fluid page articles">
      <div class="request-overlay"></div>
      <div class="row article-header sticky-top">
        <div class="col col-sm-1 actionCol">Curation</div>
        <sortDateTableHeader container-class="col-sm-1 pubDateCol" date-type="articlePubDate" />
        <sortDateTableHeader container-class="col-sm-1 curationDateCol" date-type="curationDate" />
        <localSortTableHeader container-class="col-sm-2 titleCol" field-type="headline" />
        <localSortTableHeader container-class="col-sm-1 sourceCol" field-type="source" />
        <div class="col col-sm-6 entitiesCol"><entitiesTableHeader /></div>
      </div>
      <div
        v-for="(article, articleIndex) in articles"
        :id="'article-' + article._id"
        :key="article._id"
        v-inview:class="{ onpage: true }"
        v-inview:class.leave="{ onpage: false }"
        class="row article"
        :class="{
          disabled: !_.isEmpty(article.curationDisabled),
          'modified-curation': _.get(article, 'curation.count', 0) > 1
        }"
      >
        <curation-disabled-note :curation-disabled="article.curationDisabled" @resetPage="resetPage" />
        <div class="col col-sm-3 curationSection">
          <div class="row">
            <div class="col col-sm-3 actionCol text-center">
              <approveButton :article="article" :article-index="articleIndex" />
              <excludeButton ref="initialBtn" :article="article" :article-index="articleIndex" />
            </div>
            <div class="col col-sm-4 pubDateCol">
              {{ formattedDate(article.articlePubDateMs) }}
              <div class="local-time">local: {{ formattedDate(article.articlePubDateMs, true) }}</div>
              <documentHashCopy :document-hash="article.documentHash" />
            </div>
            <div class="col col-sm-5 curationDateCol">
              <curationDateInfo :article="article" :show-document-hash="false" />
            </div>
          </div>
        </div>

        <div class="col col-sm-3 articleDetailsSection">
          <div class="row">
            <div class="col article-body">
              <div class="col titleCol">
                <a
                  data-toggle="modal"
                  data-target="#articleModal"
                  href="#"
                  tabindex="-1"
                  @click="openArticle(article)"
                  >{{ getTitle(article) }}</a
                >
              </div>
              <div class="sourceCol float-right rounded border border-info p-1">
                <span class="source">{{ _.get(article, 'source.name', article.source) }}</span
                ><br />
                <span class="sourceType">{{ article.sourceType }}</span
                ><br />
                <span class="humanLanguage badge badge-info text-uppercase">{{
                  getLanguage(article.humanLanguage)
                }}</span>
                <country-flag :country="getFlag(article)" size="big" class="language-flag" />
              </div>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 entitiesSection">
          <entitiesSection :article="article" :article-index="articleIndex" :curating-from="curatingFrom" />
        </div>
      </div>
      <div v-if="_.get(articles, 'length') === 0" id="noArticles" class="row">
        <div class="col screen-centered"><b>No Articles Found</b></div>
      </div>
      <div v-if="showLoadMore" id="moreArticles" class="row">
        <div class="col screen-centered">
          <button
            v-inview:class="{ onpage: true }"
            v-inview:class.leave="{ onpage: false }"
            type="button"
            class="btn-sm btn-primary"
            @click="loadMoreArticles"
          >
            load more ...
          </button>
        </div>
      </div>
    </div>
    <articleModal />
    <articleExcludeModal @update-articles="updateArticles" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import approveButton from '../components/buttons/approveButton'
import excludeButton from '../components/buttons/excludeButton'
import curationStats from '../components/curationStats'
import controlCompanyFilter from '../components/formControls/controlCompanyFilter'
import controlDateFilter from '../components/formControls/controlDateFilter'
import controlDocumentHashFilter from '../components/formControls/controlDocumentHashFilter'
import controlHeadlineFilter from '../components/formControls/controlHeadlineFilter'
import controlScoreModifiedFilter from '../components/formControls/controlScoreModifiedFilter'
import controlUserFilter from '../components/formControls/controlUserFilter'
import curationDisabledNote from '../components/curationDisabledNote'
import documentHashCopy from '../components/documentHashCopy'
import articleModal from '../components/articleModal'
import entitiesSection from '../components/entites/entitiesSection'
import entitiesTableHeader from '../components/entites/entitiesTableHeader'
import articleExcludeModal from '../components/articleExcludeModal'
import sortDateTableHeader from '../components/sortDateTableHeader'
import localSortTableHeader from '../components/localSortTableHeader'
import curationDateInfo from '../components/curationDateInfo'
import xlibs from '../js/xlibs'
import xcomputed from '../js/xcomputed'
import subNav from '../components/subNav'

export default {
  name: 'Ingested',
  components: {
    approveButton,
    excludeButton,
    curationStats,
    controlCompanyFilter,
    controlDateFilter,
    controlDocumentHashFilter,
    controlHeadlineFilter,
    controlScoreModifiedFilter,
    controlUserFilter,
    curationDateInfo,
    curationDisabledNote,
    documentHashCopy,
    entitiesSection,
    entitiesTableHeader,
    sortDateTableHeader,
    localSortTableHeader,
    articleModal,
    articleExcludeModal,
    subNav
  },
  props: [],

  data() {
    return {
      currentUser: {},
      page: 0,
      toggleIdSearchOn: false
    }
  },
  computed: {
    ...xcomputed,
    ...mapGetters(['articles', 'count', 'modalArticle', 'articleFilters', 'collectionType', 'collectionName'])
  },
  mounted() {
    this.currentUser = xlibs.getUser()
  },
  created() {
    window.addEventListener('keydown', this.keyNavigation)
    this.$store.dispatch('resetArticleFilters', 'ingested')
    this.setCuratingFrom(this.curatingFrom)
    this.resetPage()
  },
  destroyed() {
    window.removeEventListener('keydown', this.keyNavigation)
  },
  methods: {
    ...xlibs,
    async resetPage() {
      await this.clearStores()
      // dropdown defaults
      this.applyFilters()
      const initialFocus = $(_.get(this, '$refs.initialBtn[0].$el', {}))
      initialFocus.focus()
    },
    async updateArticles(articles, totalCount) {
      await this.$store.dispatch('setArticles', articles)
      await this.$store.dispatch('updateCount', totalCount)
    },
    updateUser(user) {
      console.log('updateUser', user)
      this.$store.dispatch('setArticleFilter', { user })
    },
    loadMoreArticles() {
      this.$store.dispatch('setArticleFilter', { page: this.articleFilters.page + 1 })
      xlibs.toPrevItem()
      xlibs.applyFilters.call(this)
    },
    textFilterResult() {
      console.log('search filter')
    },
    filterByISIN() {
      this.applyFilters()
    }
  },
  sockets: {
    xmanCurationSocket: xlibs.xmanCurationSocket
  }
}
</script>
